import { connect } from "react-redux";
import { createCertificate, deleteCertificate, getCertificates, updateCertificate } from "../../actions/dictionaryAction";
import { ToastContainer } from "react-toastify";
import AcSimpleTable from "../../components/AcSimpleTable";
import ButtonAdd from "../../components/ButtonAdd";
import InputField from "../../components/InputField";
import SubHeader from "../../components/SubHeader";
import SmallPopup from "../../components/SmallPopup";
import DropDown from "../../components/DropDown";
import MediumPopup from "../../components/MediumPopup";
import { useEffect, useRef, useState } from "react";
import { showErrorMessage, showSuccessMessage } from "../../utils/CustomNotification";
import defaultValues from "../../constant/defaultValues";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { isEmpty, size } from "lodash";
import errorMessages from "../../constant/errorMessages";
import { countryList } from "../../actions/countryAction";
import regExp from "../../constant/regExpressions";

function ManageCertificates(props) {
  const { admin, CERTIFICATE_NAME_FIELD_MAX_LENGTH, CERTIFICATE_SLUG_FIELD_MAX_LENGTH } = defaultValues;
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [certificateList, setCertificateList] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [editCertificateId, setEditCertificateId] = useState();
  const [errors, setErrors] = useState({});
  const [certificateName, setCertificateName] = useState("");
  const [slug, setSlug] = useState("");
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [deleteCertificateId, setDeleteCertificateId] = useState();
  const [tradeMode, setTradeMode] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);
  const initialRef = useRef(true);
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
  const [current_page, setCurrentPage] = useState(1);

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%",
    },
    {
      name: "Certificate Name",
      selector: (row) => (
        <div className="tbl-text-wrap-row">
          {row.name ? row.name : "-"}
        </div>
      ),
      columnName: "name",
      resizable: true,
      width: "15%",
      sortable: true
    },
    {
      name: "Slug",
      selector: (row) => (
        <div className="tbl-text-wrap-row">{row.slug ? row.slug : "-"}</div>
      ),
      columnName: "slug",
      resizable: true,
      width: "25%",
      sortable: true
    },
    {
      name: "Trade Mode",
      selector: (row) => (
        <div className="text-wrap">{row.trade_mode ? row.trade_mode : "-"}</div>
      ),
      columnName: "trade_mode",
      resizable: true,
      width: "10%",
      sortable: true
    },
    {
      name: "Country Name",
      selector: (row) => <div className="tbl-text-wrap-row">{row.country ? row.country.name : "Global"}</div>,
      resizable: true,
      width: "10%",
      columnName: "country_name",
      sortable: true
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "10%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "10%",
      // minWidth: "200px"
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getCertificateList(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const onSearchTextBoxChanged = (e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setLimit(admin.LIMIT);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      getCertificateList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }
  };

  const getCertificateList = async (offset, limit, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getCertificates(offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        setCertificateList([]);
        setCertificateList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const fetchCountries = async () => {
    await props
      .getCountries()
      .then((response) => {
        if (response.status === true) {
          const opt = [
            {
              value: "Global",
              label: "Global"
            },
          ];
          response.data.map((country) => (opt.push({
            value: country.id,
            label: country.name
          })));
          setCountryList(opt);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  };

  const handleEditClick = async (row) => {
    // await props.showLoading();
    setErrors({});
    setCertificateName(row.name);
    setSlug(row.slug);
    setEditCertificateId(row.id);
    const selectedTradeModeOption = tradeModeOption.find(
      (option) => option.value == row.trade_mode
    );
    setTradeMode(selectedTradeModeOption);
    let selectedCountryOption = countryList.find(
      (option) => option.value == row.country_id
    );
    if (row.country_id == null) {
      selectedCountryOption =
      {
        value: "Global",
        label: "Global"
      }
    }
    setCountry(selectedCountryOption);
    setMediumModalShow(true);

    // await props.hideLoading();
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    errObj.availableCertificate = "";
    if (e.target.name === "certificate_name") {
      if (isEmpty(e.target.value)) {
        errObj.certificateName = errorMessages.FIELD_REQUIRED;
      } else if (!isEmpty(e.target.value)) {
        if (size(e.target.value) > CERTIFICATE_NAME_FIELD_MAX_LENGTH) {
          errObj.certificateName = errorMessages.MAX_CHARACTERS_REACHED;
        } else if (!(regExp.certificate_name.test(e.target.value))) {
          errObj.certificateName = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          errObj.certificateName = "";
        }
      }
    }

    if (e.target.name === "slug") {
      if (isEmpty(e.target.value)) {
        errObj.slug = errorMessages.FIELD_REQUIRED;
      } else if (!isEmpty(e.target.value)) {
        if (size(e.target.value) > CERTIFICATE_SLUG_FIELD_MAX_LENGTH) {
          errObj.slug = errorMessages.MAX_CHARACTERS_REACHED;
        } else if (!(/^[A-Z0-9_().,-]+$/.test(e.target.value)) || (/^[0-9]+$/.test(e.target.value))) {
          errObj.slug = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          errObj.slug = "";
        }
      }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;
    if (isEmpty(certificateName)) {
      errFlag = 1;
      er.certificateName = errorMessages.FIELD_REQUIRED;
    } else if (!isEmpty(certificateName)) {
      if (size(certificateName) > CERTIFICATE_NAME_FIELD_MAX_LENGTH) {
        errFlag = 1;
        er.certificateName = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (!(regExp.certificate_name.test(certificateName))) {
        errFlag = 1;
        er.certificateName = errorMessages.IN_VALID_FIELD_FORMAT;
      }
    }

    if (isEmpty(slug)) {
      errFlag = 1;
      er.slug = errorMessages.FIELD_REQUIRED;
    } else if (!isEmpty(slug)) {
      if (size(slug) > CERTIFICATE_SLUG_FIELD_MAX_LENGTH) {
        errFlag = 1;
        er.slug = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (!(/^[A-Z0-9_().,-]+$/.test(slug)) || (/^[0-9]+$/.test(slug))) {
        errFlag = 1;
        er.slug = errorMessages.IN_VALID_FIELD_FORMAT;
      }
    }

    if (isEmpty(tradeMode)) {
      errFlag = 1;
      er.tradeMode = errorMessages.FIELD_REQUIRED;
    }

    if (isEmpty(country)) {
      errFlag = 1;
      er.country = errorMessages.FIELD_REQUIRED;
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setMediumModalShow(false);
    // console.log('country.value----country.value----', country);
    if (validForm()) {
      props.showLoading();
      if (editCertificateId > 0) {
        const reqObj = {
          name: certificateName,
          slug: slug,
          trade_mode: tradeMode.value,
          country_id: country.value,
        };
        await props
          .updateCertificate(editCertificateId, reqObj)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getCertificateList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error == 'The data has already been taken.') {
                errObj.availableCertificate = response.error;
              }
              if (response.error.name) {
                errObj.certificateName = response.error.name[0];
              }
              if (response.error.slug) {
                errObj.slug = response.error.slug[0];
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          name: certificateName,
          slug: slug,
          trade_mode: tradeMode.value,
          country_id: country.value,
        };
        await props
          .createCertificate(reqData)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getCertificateList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error == 'The data has already been taken.') {
                errObj.availableCertificate = response.error;
              }
              if (response.error.name) {
                errObj.certificateName = response.error.name[0];
              }
              if (response.error.slug) {
                errObj.slug = response.error.slug[0];
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "certificate_name") {
      setCertificateName(e.target.value);
    } else if (e.target.name === "slug") {
      setSlug(e.target.value);
    }
  };

  const handleAddCertificateClick = () => {
    setCertificateName();
    setCountry(null);
    setTradeMode();
    setSlug();
    setErrors({});
    setEditCertificateId(0);
    setMediumModalShow(true);
  };

  const handleCountryChange = (cval) => {
    setCountry(cval);
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteCertificateId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteCertificate(deleteCertificateId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("Certificate Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getCertificateList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        } else if (response.status === false) {
          if (
            response.error === "The certificate is already used by some company"
          ) {
            showErrorMessage(response.error);
          }
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const tradeModeOption = [
    { label: "Import", value: "Import" },
    { label: "Export", value: "Export" },
    { label: "Shipping & Logistics", value: "Shipping & Logistics" },
    { label: "Trader", value: "Trader" },
    { label: "Manufacturer", value: "Manufacturer" },
  ];

  const handleTradeMode = (val) => {
    // console.log('val-------------------', val);
    setTradeMode(val);
  };


  useEffect(() => {
    if (initialRef.current === true) {
      initialRef.current = false;
      fetchCountries();
      getCertificateList(offset, limit, columnName, sortDirection, searchTxt);
    }

  }, []);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editCertificateId > 0 ? "Edit Certificate" : "Create Certificate"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editCertificateId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}
              <div className="row my-2">
                <div className="col-xl-6 my-2">
                  <label className="popup-input-lable field-required">
                    Certificate Name
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter Certificate Name"}
                    name="certificate_name"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={certificateName}
                    autoComplete="off"
                    maxLength={CERTIFICATE_NAME_FIELD_MAX_LENGTH + 1}
                  ></InputField>

                  {errors.certificateName && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.certificateName}
                    </p>
                  )}
                </div>

                <div className="col-xl-6 my-2">
                  <label className="popup-input-lable field-required">
                    Slug
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter Slug"}
                    name="slug"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={slug}
                    autoComplete="off"
                    maxLength={CERTIFICATE_SLUG_FIELD_MAX_LENGTH + 1}
                  ></InputField>

                  {errors.slug && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.slug}
                    </p>
                  )}
                </div>
                <div className='col-xl-6 my-2'>
                  <label className="profile-text">
                    Trade Mode
                    <span className="text-danger"> *</span>
                  </label>

                  <DropDown
                    placeholder={"Select Trade Mode"}
                    options={tradeModeOption}
                    onChange={handleTradeMode}
                    value={tradeMode}
                    name="trade_mode"
                  ></DropDown>

                  {errors.tradeMode && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.tradeMode}
                    </p>
                  )}
                </div>
                <div className="col-xl-6 my-2">
                  <label className="profile-text">
                    Select Country
                    <span className="text-danger"> *</span>
                  </label>
                  <DropDown
                    placeholder={"Select Country"}
                    className={"input-error-border"}
                    options={countryList}
                    value={country}
                    onChange={handleCountryChange}
                  ></DropDown>
                  {errors.country && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.country}
                    </p>
                  )}
                </div>
                {errors.availableCertificate && (
                  <p
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.availableCertificate}
                  </p>
                )}
              </div>
            </form>
          </MediumPopup>
          <SmallPopup
            title="Delete Certificate"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className="row my-3">
              <div className="col-md-12 p-0">
                Are you sure you want to delete Certificate?
              </div>
            </div>
          </SmallPopup>

          <SubHeader
            layer1="Dictionary Management"
            layer2="Certificates"
            layer3="Verified"
            pageName="Certificates"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onChange={onSearchTextBoxChanged}
                    value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  <ButtonAdd
                    type="button"
                    className="btn-add me-0"
                    onClick={handleAddCertificateClick}
                  >
                    {" "}
                    <i className="fa-solid fa-circle-plus"></i> Add New Certificate
                  </ButtonAdd>
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {certificateList && (
                    <AcSimpleTable
                      data={certificateList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getCertificateList}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {

    getCountries: () => dispatch(countryList()),
    getCertificates: (offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(getCertificates(offset, limit, columnName, sortDirection, searchTxt)),
    updateCertificate: (id, data) => dispatch(updateCertificate(id, data)),
    createCertificate: (data) => dispatch(createCertificate(data)),
    deleteCertificate: (id) => dispatch(deleteCertificate(id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCertificates);
