import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import defaultValues from "../../constant/defaultValues";
import { approvedHsCodeList, createHsCode, deleteHsCode, getHsCodes, updateHsCode } from "../../actions/dictionaryAction";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/CustomNotification";
import SubHeader from "../../components/SubHeader";
import InputField from "../../components/InputField";
import ButtonAdd from "../../components/ButtonAdd";
import AcSimpleTable from "../../components/AcSimpleTable";
import { ToastContainer } from "react-toastify";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import MediumPopup from "../../components/MediumPopup";
import TextAreaField from "../../components/TextAreaField";
import { debounce, isEmpty, size } from "lodash";
import errorMessages from "../../constant/errorMessages";
import SmallPopup from "../../components/SmallPopup";
import { useParams } from "react-router-dom";
import RadioButtonField from "../../components/RadioButtonField";
import DropDown from "../../components/DropDown";
import { getHSCodeSearchList } from "../../actions/defaultDataAction";

function ManageHsCodes(props) {
  const { admin, HS_CODE_MAX_LENGTH } = defaultValues;
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [hsCodeList, setHsCodeList] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [editHsCodeId, setEditHsCodeId] = useState();
  const [errors, setErrors] = useState({});
  const [hsCodeValue, setHsCodeValue] = useState("");
  const [hsCodeDescription, setHsCodeDescription] = useState("");
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [deleteHsCodeId, setDeleteHsCodeId] = useState();
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
  const [current_page, setCurrentPage] = useState(1);

  const [hsCodeAction, setHsCodeAction] = useState("Approve");
  const [hsCodeSearchList, setHsCodeSearchList] = useState([]);
  const [approvedHsCode, setApprovedHsCode] = useState();
  const initialRef = useRef(true);

  const { hs_code_status } = useParams();

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%",
    },
    {
      name: "HS Code",
      selector: (row) => <div className="text-wrap">{row.code}</div>,
      columnName: "code",
      resizable: true,
      width: "10%",
      sortable: true
    },
    {
      name: "Description",
      selector: (row) => <div className="text-wrap">{row.description}</div>,
      resizable: true,
      width: "45%",
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "15%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "10%",

    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getHsCodesList(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const onSearchTextBoxChanged = debounce((e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setLimit(admin.LIMIT);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      getHsCodesList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }
  });

  const getHsCodesList = async (offset, limit, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getHsCodes(hs_code_status, offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        // console.log("responsehscodes*", response);
        setHsCodeList([]);
        setHsCodeList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const handleEditClick = async (row) => {
    // await props.showLoading();
    setErrors({});
    setHsCodeAction("Approve");
    setApprovedHsCode("");
    setHsCodeValue(row.code);
    setHsCodeDescription(row.description);
    setEditHsCodeId(row.id);
    approvedHsCodeList();
    setMediumModalShow(true);

    // await props.hideLoading();
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "hs_code") {
      if (isEmpty(e.target.value)) {
        errObj.hsCodeValue = errorMessages.FIELD_REQUIRED;
      } else if (!(/^\d+(\.\d+)*$/.test(e.target.value))) {
        errObj.hsCodeValue = errorMessages.IN_VALID_FIELD_FORMAT;
      } else if (size(e.target.value) > HS_CODE_MAX_LENGTH) {
        errObj.hsCodeValue = errorMessages.MAX_CHARACTERS_REACHED;
      } else {
        errObj.hsCodeValue = "";
      }
    }

    // 

    if (e.target.name === "hs_code_description") {
      if (isEmpty(e.target.value)) {
        errObj.hsCodeDescription = errorMessages.FIELD_REQUIRED;
      } else {
        errObj.hsCodeDescription = "";
      }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;

    if (isEmpty(hsCodeValue)) {
      errFlag = 1;
      er.hsCodeValue = errorMessages.FIELD_REQUIRED;
    } else if (!(/^\d+(\.\d+)*$/.test(hsCodeValue))) {
      errFlag = 1;
      er.hsCodeValue = errorMessages.IN_VALID_FIELD_FORMAT;
    } else if (size(hsCodeValue) > HS_CODE_MAX_LENGTH) {
      errFlag = 1;
      er.hsCodeValue = errorMessages.MAX_CHARACTERS_REACHED;
    }

    if (isEmpty(hsCodeDescription)) {
      errFlag = 1;
      er.hsCodeDescription = errorMessages.FIELD_REQUIRED;
    }

    if (hsCodeAction == "Map_To_Other") {
      errFlag = 0;
      er.hsCodeDescription = "";
      if (isEmpty(approvedHsCode)) {
        errFlag = 1;
        er.approvedHsCode = errorMessages.FIELD_REQUIRED;
      }
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setMediumModalShow(false);

    if (validForm()) {
      props.showLoading();
      if (editHsCodeId > 0) {
        const reqObj = {
          code: hsCodeValue,
          description: hsCodeDescription,
          action: hsCodeAction,
          mapped_hs_code: approvedHsCode,
        };
        await props
          .updateHsCode(editHsCodeId, reqObj)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getHsCodesList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let er = {};
              if (response.error) {
                if (response.error == "The data has already been taken.") {
                  er.hsCodeValue = response.error;
                }
                if (response.error.code) {
                  er.hsCodeValue = response.error.code[0];
                }
              }
              setErrors(er);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          code: hsCodeValue,
          description: hsCodeDescription
        };
        await props
          .createHsCode(reqData)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getHsCodesList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              // console.log("Error =========== ", response);
              if (response.error) {
                if (response.error.code) {
                  errObj.hsCodeValue = response.error.code[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleChange = (e) => {
    setHsCodeValue(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setHsCodeDescription(e);
  }

  const handleAddHsCodeClick = () => {
    setHsCodeValue();
    setHsCodeDescription();
    setErrors({});
    setEditHsCodeId(0);
    setMediumModalShow(true);
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteHsCodeId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteHsCode(deleteHsCodeId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("Hs Code Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getHsCodesList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        } else if (response.status === false) {
          if (response.error === "This hs code cannot be deleted as it is associated with existing users.") {
            showErrorMessage(response.error);
          }
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleHsCodeActionChange = (e, hs_code_action) => {
    if (hs_code_action === "Approve") {
      setHsCodeAction("Approve");
    } else if (hs_code_action === "Map_To_Other") {
      setHsCodeAction("Map_To_Other");
    }
  };

  const approvedHsCodeList = async () => {
    await props.approvedHsCodeList()
      .then((response) => {
        if (response) {
          const options = response.data.map((hsCode) => ({
            value: hsCode.id,
            label: hsCode.code,
          }));

          setHsCodeSearchList(options);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  }

  const handleInputChange = (searchbox, x) => {
    if (searchbox !== '') {
      if (searchbox.length >= 3) {
        fetchHSCodeBasedOnSearch(searchbox);
      }
    }
  }

  const fetchHSCodeBasedOnSearch = async (searchbox = "") => {
    if (!isEmpty(searchbox)) {

      await props.getHSCodeSearch(searchbox)
        .then((response) => {
          if (response) {
            const options = response.map((hsCode) => ({
              value: hsCode.id,
              label: hsCode.code,
            }));
            const newHsCodeList = [...hsCodeSearchList];

            options.forEach(option => {
              if (!newHsCodeList.some(existing => existing.value === option.value)) {
                newHsCodeList.push(option);
              }
            });
            setHsCodeSearchList(newHsCodeList);
          }

        })
        .catch((err) => {
          console.log("Error =", err);
          showErrorMessage("Something went wrong");
        });
    }

  }

  const handleApprovedHsCodeChange = (val) => {
    setApprovedHsCode(val);
  };

  useEffect(() => {
    // if (initialRef.current === true) {
    //   initialRef.current = false;
    getHsCodesList(offset, limit, columnName, sortDirection, searchTxt);
    // }

  }, [hs_code_status]);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editHsCodeId > 0 ? "Edit Hs Code" : "Create Hs Code"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editHsCodeId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}

              <div className="row my-2">

                <div className="col-xl-12 my-3">
                  <label className="popup-input-lable field-required">
                    Hs Code
                  </label>
                  <InputField
                    className="hsCodeInputField"
                    placeholder={"Enter Hs Code"}
                    name="hs_code"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={hsCodeValue}
                    autoComplete="off"
                    disabled={hsCodeAction == "Map_To_Other" ? true : false}
                    maxLength={HS_CODE_MAX_LENGTH + 1}
                  ></InputField>

                  {errors.hsCodeValue && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.hsCodeValue}
                    </p>
                  )}
                </div>

                <div className="col-xl-12 my-3">
                  <label className="popup-input-lable field-required">
                    Description
                  </label>
                  <TextAreaField
                    name="hs_code_description"
                    value={hsCodeDescription}
                    onChange={handleDescriptionChange}
                    // maxLength={500 + 1}
                    onBlur={handleFocusOut}
                    disabled={hsCodeAction == "Map_To_Other" ? true : false}
                  />
                  {errors.hsCodeDescription && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.hsCodeDescription}
                    </p>
                  )}
                </div>

                {hs_code_status == 0 && (
                  <div>
                    <div className="col-xl-12 my-3">
                      <label className="profile-text">
                        {" "}
                        Hs Code Action <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex">
                        <div className="mt-2">
                          <RadioButtonField
                            onChange={(e) => handleHsCodeActionChange(e, "Approve")}
                            checked={hsCodeAction == "Approve"}
                          />
                          <label className="">Approve</label>
                        </div>
                        <div className="mt-2">
                          <RadioButtonField
                            onChange={(e) =>
                              handleHsCodeActionChange(e, "Map_To_Other")
                            }
                            checked={hsCodeAction == "Map_To_Other"}
                          />
                          <label className="">Map to Other Hs Code</label>
                        </div>
                      </div>
                    </div>


                    {/* <div className="mt-2"> */}
                    {hsCodeAction == "Map_To_Other" && (
                      <div className="row">

                        <div className="col-xl-6">
                          <label className="profile-text">
                            Select Approved Hs Code
                            <span className="text-danger"> *</span>
                          </label>

                          <DropDown
                            placeholder={"Select Approved Hs Code"}
                            className={"input-error-border"}
                            options={hsCodeSearchList}
                            onChange={handleApprovedHsCodeChange}
                            onInputChange={handleInputChange}
                            value={approvedHsCode}
                          ></DropDown>
                          {errors.approvedHsCode && (
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.approvedHsCode}
                            </p>
                          )}
                        </div>

                      </div>
                    )}
                    {/* </div> */}


                  </div>
                )}
              </div>
            </form>
          </MediumPopup>
          <SmallPopup
            title="Delete Hs Code"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className='row my-3'>
              <div className="col-md-12 p-0">Are you sure you want to delete Hs code?</div>
            </div>

          </SmallPopup>

          <SubHeader
            layer1="Dictionary Management"
            layer2="Hs Codes"
            layer3={hs_code_status == 1 ? "Verified" : "Curated"}
            pageName="Hs Codes"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onKeyUp={onSearchTextBoxChanged}
                    // value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  {hs_code_status == 1 && (
                    <ButtonAdd
                      type="button"
                      className="btn-add me-0"
                      onClick={handleAddHsCodeClick}
                    >
                      {" "}
                      <i className="fa-solid fa-circle-plus"></i> Add New HS Code
                    </ButtonAdd>
                  )}
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {hsCodeList && (
                    <AcSimpleTable
                      data={hsCodeList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getHsCodesList}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHsCodes: (hs_code_status, offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(getHsCodes(hs_code_status, offset, limit, columnName, sortDirection, searchTxt)),
    getHSCodeSearch: (searctText) => dispatch(getHSCodeSearchList(searctText)),
    approvedHsCodeList: () => dispatch(approvedHsCodeList()),
    updateHsCode: (id, data) => dispatch(updateHsCode(id, data)),
    createHsCode: (data) => dispatch(createHsCode(data)),
    deleteHsCode: (id) => dispatch(deleteHsCode(id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHsCodes);
