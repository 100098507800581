import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { ToastContainer } from "react-toastify";

import defaultValues from '../../constant/defaultValues';

import { useAuth } from '../../AuthProvider';
import { getRandomInteger } from '../../utils/CustomNotification';

import { hideLoading, showLoading } from '../../actions/loaderAction';

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from '../../components/InputField';
import SubHeader from '../../components/SubHeader';
import BuyerSupplierFilter from '../../components/memberFilter/buyerSupplierFilter';

import Emitter from '../../utils/Emitter';
import { debounce, find, isEmpty, size } from 'lodash';
import { FilterBuyersSupplier } from '../../actions/shipmentAction';
import MediumPopup from '../../components/MediumPopup';



function BuyerSupplier(props) {

    const { admin, COMPANY_DEFAULTS } = defaultValues;
    const statusList = COMPANY_DEFAULTS.STATUS;
    const { user, isEndUser, isSuperAdmin } = useAuth();

    const [memberList, setMemberList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [searchTxt, setSearchTxt] = useState("");
    const [current_page, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState(0);

    const initialRef = useRef(true);
    const [columnName, setColumnName] = useState("Company Name");
    const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);

    const [mediummodalShow, setMediumModalShow] = useState(false);

    const [searchFilterObj, setFilterObj] = useState({
        filterBy: "hs_code",
        offset: admin.OFFSET,
        limit: admin.LIMIT,
        columnName: "Company Name",
        sortDirection: admin.SORT_DIRECTION,
        filter_val: "",
        selected_companies: [],
        selected_hscodes: [],
        selected_services: [],
        selected_target_country: [],
        selected_country: [],
        table_search: "",
        findWhom: "Buyer",
        current_page: 1
    })

    const columns = [
        {
            name: 'Sr No',
            selector: (row, idx) => {
                return (
                    <>{offset + (idx + 1)}</>
                )
            },
            resizable: true,
            // width: "100px"
            width: "5%"
        },
        {
            name: 'Unique Number',
            selector: row => <div className='text-wrap'>{'OTD' + getRandomInteger(100001, 999999)}</div>,
            resizable: true,
            // width: "150px"
            width: "12%",
            // columnName: "id",
            sortable: false
        },
        {
            name: 'Company Name',
            selector: row => <div className='tbl-text-wrap-row'>{row.company_name}</div>,
            //  width:"250px"
            width: "15.5%",
            // columnName: "company_name",
            sortable: true
        },
        {
            name: 'HS Code',
            selector: row => <div className="tbl-text-wrap-row"> {(row.hs_code ? row.hs_code : '')}</div>,
            resizable: true,
            // width: "200px"
            width: "10%",
            // columnName: "first_name",
            sortable: true
        },
        {
            name: 'Product',
            selector: row => <div className='tbl-text-wrap-row'>{row.product_desc}</div>,
            resizable: true,
            // width: "250px"
            width: "20%",
            // columnName: "email",
            sortable: true
        },
        {
            name: 'Trade Mode',
            selector: row => <div className='text-wrap'>{row.trade_mode}</div>,
            resizable: true,
            //width: "150px"
            width: "10%",
            // columnName: "shipment_mode",
            sortable: true
        },
        {
            name: 'Country',
            selector: row => <div className='text-wrap'>{row.country}</div>,
            resizable: true,
            // width: "150px"
            width: "10%",
            // columnName: "country_name",
            sortable: true
        },
        {
            name: 'Status',
            selector: row => <div className='text-wrap'>{"Active"}</div>,
            resizable: true,
            // width: "150px"
            width: "9%",
            // columnName: "country_name",
            sortable: false
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="text-wrap">
                    <button className="btn action-icon-btn m-0 p-0 mx-2 " data-toggle="tooltip" data-placement="top" title="View" onClick={(e) => handleViewClick(e)}>
                        <i className="fa-regular fa-eye icon-eyes"></i>
                    </button>
                </div>
            ),
            // resizable: true,
            // width: "100px"
            width: "8%"
        }

    ];

    const handleViewClick = (e) => {
        setMediumModalShow(true);
    }

    const handleSort = async (column, sortDirection) => {
        if (column.name != undefined && column.name != "" && column.name != null) {
            setColumnName(column.name);
            setSortDirection(sortDirection);
            filterMemberData(
                admin.OFFSET,
                admin.LIMIT,
                column.name,
                sortDirection,
                searchTxt,
                current_page
            );
        }
    };

    const onSearchTextBoxChanged = debounce((e) => {
        // console.log("Search Val === ", e.target.value);
        // setSearchTxt(e.target.value);
        let searchVal = e.target.value;
        //props.showLoading();
        if ((searchVal.length >= 3 || searchVal.length == 0) && (searchVal != searchTxt)) {
            let sFilter = { ...searchFilterObj };
            sFilter.table_search = searchVal;
            setFilterObj(sFilter);
            setOffset(admin.OFFSET);
            setLimit(admin.LIMIT);
            setColumnName("Company Name");
            setSortDirection(admin.SORT_DIRECTION);
            setSearchTxt(e.target.value);
            setCurrentPage(1);

            setTimeout(async () => {
                await filterMemberData(admin.OFFSET, admin.LIMIT, "Company Name", admin.SORT_DIRECTION, searchVal, 1);

            }, 1500);

        }
    });

    const filterMemberData = async (ofst = 0, lmt = 10, columnName = "Company Name", sortDirection = "DESC", searchval = "", cur_page = 1) => {
        // console.log("filterMemberData  function args === ", ofst, lmt);
        props.showLoading();
        let fObj = { ...searchFilterObj };
        if (fObj !== undefined && fObj !== 0 && fObj !== null && fObj !== "") {
            fObj.offset = ofst;
            fObj.limit = lmt;
            fObj.columnName = columnName;
            fObj.sortDirection = sortDirection;
            fObj.table_search = searchval;
            fObj.current_page = cur_page;

            await props.FilterBuyersSupplier(fObj).then((response) => {
                if (response) {
                    if (response.status === true) {
                        setMemberList(response.data);
                        settotalRows(response.metadata.total);
                    }
                }
            }).catch((err) => {
                console.log("Error ==== ", err);
            });

        }
        props.hideLoading();
    }

    const setMembersFilters = (newState) => {
        // console.log("newState =================== ", newState);

        newState.table_search = "";
        newState.current_page = 1;
        setSearchTxt(null);
        setCurrentPage(1);
        document.getElementById("filter-text-box").value = null;
        setFilterObj(newState);
    }

    const filterApplyOnMembers = (filterObj) => {
        filterMemberData(admin.OFFSET, admin.LIMIT, "Company Name", admin.SORT_DIRECTION, searchTxt, 1);

    }

    useEffect(() => {
        async function fetchData() {
            // You can await here
            // getRegisteredMembers(offset, limit, searchTxt);
            initialRef.current = false;
            await filterMemberData(admin.OFFSET, admin.LIMIT, "Company Name", admin.SORT_DIRECTION, searchTxt, 1);

        }
        if (initialRef.current === true) {
            fetchData();
        }

    }, []);

    useEffect(() => {
        // Emitter.on("FILTER_MEMBERS", filterMemberData);
        Emitter.on("FILTER_MEMBERS", filterApplyOnMembers);
        Emitter.on("SET_FILTER_MEMBERS", setMembersFilters);
        return () => {
            Emitter.off("FILTER_MEMBERS", filterApplyOnMembers);
        };
    });

    return (<>
        <div className='data-table-main'>

            <div className="AcSimpleTable">
                <SubHeader
                    layer1="Buyers/Suppliers Management"
                    layer2=""
                    layer3="List"
                    pageName="Buyers/Suppliers"
                    clickable={{
                        layer1: "",
                        layer2: "",
                        layer3: ""
                    }}
                />

                <div className='filter-section'>
                    <BuyerSupplierFilter />
                </div>

                <div className='card1'>

                    <div className='table-header-name'>
                        <h5 className='table-text'>List</h5>
                        <div className='d-flex justify-content-between align-items-center p-3'>
                            <label className='mx-3'>Search</label>
                            <InputField
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                className='w-25'
                                onKeyUp={onSearchTextBoxChanged}
                                // onChange={onSearchTextBoxChanged}
                                // value={searchTxt}
                                autoComplete="off"
                            ></InputField>
                        </div>

                    </div>

                    <div className="table-wrapper">
                        <div className="table-container" style={{ overflowX: 'auto', width: "100%" }}>
                            {memberList && (
                                <AcSimpleTable
                                    data={memberList}
                                    title=""
                                    pagination={true}
                                    paginationPageSize={limit}
                                    columns={columns}
                                    progressPending={loading}
                                    paginationTotalRows={totalRows}
                                    fixedColumnsStart={admin.FIXED_COLUMNS_START}
                                    getData={filterMemberData}
                                    offset={offset}
                                    limit={limit}
                                    columnName={columnName}
                                    sortDirection={sortDirection}
                                    setLimit={setLimit}
                                    setOffset={setOffset}
                                    // setColumnName={setColumnName}
                                    // setSortDirection={setSortDirection}
                                    setLoading={setLoading}
                                    paginationPerPage={limit}
                                    paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                                    // className="company-table"
                                    striped
                                    searchTxt={searchTxt}
                                    sortServer
                                    onSort={handleSort}
                                    defaultSortAsc={false}
                                    current_page={current_page}
                                    setCurrentPage={setCurrentPage}
                                />
                            )}
                        </div>
                    </div>

                </div>

            </div>

            <MediumPopup
                title={"Access Denied!"}
                mediummodalShow={mediummodalShow}
                setMediumModalShow={setMediumModalShow}
            >
                <div className='text-center'>
                    <b>
                        You do not have access to view more details.
                    </b></div>
                <div className='text-center'>
                    If you wish to view additional details of the buyer or supplier, please contact an Admin or email  <a href="mailto:{process.env.REACT_APP_SUPPORT_MAIL}">{process.env.REACT_APP_SUPPORT_MAIL}.</a>
                </div>

            </MediumPopup >
            <ToastContainer />
        </div >

    </>)
}

const mapStateToProps = state => {

    return {
        // member_list: state.member.memberlist,
        // authError: state.auth.errorMessages
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // getMembers: (offset, limit, searchTxt) => dispatch(getMemberList(offset, limit, searchTxt)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        FilterBuyersSupplier: (filterData) => dispatch(FilterBuyersSupplier(filterData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerSupplier);
