import React from "react";
import DataTable from 'react-data-table-component';
import NoDataFound from "./common/NoDataFound";
import NoDataFoundText from "./common/NoDataFoundText";

const AcSimpleTable = (props) => {

  const tableCustomStyles = {
    rows: {
      style: {
        color: "#000000",
        backgroundColor: "#ffffff"
      },
      stripedStyle: {
        color: "#000000",
        backgroundColor: "#f9f9f9"
      }
    },
    table: {
      style: {
        width: '100%',
      },
    },
  }



  const paginationComponentOptions = {
    rowsPerPageText: 'Per Page',
    // rangeSeparatorText: 'de',
    // selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
  };

  const handlePageChange = page => {

    let newOffset = props.limit * (page - 1);
    if (newOffset !== props.offset) {

      props.setOffset(newOffset);
      props.setCurrentPage(page);
      props.getData(newOffset, props.limit, props.columnName, props.sortDirection, props.searchTxt, page);
    }

  };

  const handlePerRowsChange = async (newPerPage, page) => {

    let newOffset = props.limit * (page - 1);
    if (newOffset !== props.offset || (props.limit !== newPerPage)) {

      props.setLoading(true);
      props.setCurrentPage(page);
      props.getData(newOffset, newPerPage, props.columnName, props.sortDirection, props.searchTxt, page);
      props.setOffset(newOffset);
      props.setLimit(newPerPage);
      props.setLoading(false);
    }
  };



  return <div style={{
    display: 'flex', // Use flex to make the wrapper responsive
    flexDirection: 'column', // Stack the table vertically within the wrapper (optional)
    overflowX: 'auto', // Enable horizontal scroll if content overflows
    width: '100%', // The wrapper should take full width of its parent container
    maxWidth: '100%', // Ensure the wrapper doesn't exceed the container width
  }} className="table-wrapper-inner"><DataTable
      title={""}
      columns={props.columns}
      data={props.data}
      progressPending={props.loading}
      pagination
      paginationServer
      paginationTotalRows={props.paginationTotalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      responsive={true}
      columnResizable={true}
      fixedColumnsStart={props.fixedColumnsStart}
      paginationRowsPerPageOptions={props.paginationRowsPerPageOptions}
      paginationPerPage={props.paginationPerPage}
      customStyles={tableCustomStyles}
      noDataComponent={<NoDataFoundText />}
      paginationComponentOptions={paginationComponentOptions}
      persistTableHead={true}
      {...props}
    /></div>;
}

export default React.memo(AcSimpleTable);
